<template>
	<el-container class="setting-page">
		<div class="reply_panel">
			<div v-for="(item ,index) in tabList" :key="index" @click="selectIndex(index)" class="reply_tab" :class="current==index?'active':''">{{item}}</div>	
		</div>
		<div class="reply_centent" v-if="current == 0">
			<div class="start-col">
				<div class="setting_sub_title">开场白</div>
			    <div class="setting_header_desc">用户进入客服会话页，自动回复此开场白。</div>
			</div>
			<div>
				<el-input
					type="textarea"
					placeholder="请输入开场白"
					rows="4"
					maxlength="32"
					show-word-limit
					v-model="startText">
				</el-input>
			</div>
			<div class="start-col" style="margin-top: 30px;">
				<div class="setting_sub_title">常见问题	</div>
			   <div style="display: flex;align-items: baseline;">
				<div class="setting_header_desc">在开场白的基础上，配置常见问题，减少客服接待压力，查看</div>
				<el-popover
					placement="bottom"
					width="200"
					trigger="hover">
					<div class="weui-desktop-popover__desc"><img src="https://qiniu.gybsky.cn/other/%E4%B8%8B%E8%BD%BD.png" alt="" height="270" data-v-90cdd032=""></div>
					<span slot="reference" style="color: #4a6eee;font-size:13px">用户侧示例。</span>
				</el-popover>
			   </div>
				
			
			</div>

			<div>
				<div style="display: flex;margin-bottom:15px" v-for="(item, index) in custQuest" :key="index">
					<div style="width: 30%;margin-right:20px">
						<el-input
							type="textarea"
							placeholder="点击新增问题"
							rows="4"
							maxlength="20"
							show-word-limit
							v-model="item.quest">
						</el-input>
					</div>
					<div style="width: 70%;">
						<el-input
							type="textarea"
							placeholder="点击新增回复"
							rows="4"
							maxlength="500"
							show-word-limit
							v-model="item.replay">
						</el-input>
					</div>
					<div @click="delQuest(index)" v-if="index != 0" style="width: 50px;margin-top: 30px;">
						<i class="el-icon-delete"></i>
					</div>
					<div v-else style="width: 50px;margin-top: 30px;">
					</div>
				</div>
				<div style="margin-top: 30px;" @click="addQuest">
					<el-button type="primary" icon="el-icon-circle-plus-outline">新增问题</el-button>
				</div>
				<div style="margin-top: 30px;text-align: end;margin-right:30px" >
					<el-button style="width: 90px;font-size: 16px" @click="questCancel"  type="info">取消</el-button>

					<el-button style="width: 90px;font-size: 16px"  @click="saveQuset" type="success" size="medium ">保存</el-button>
				</div>
			</div>
		</div>
		<div class="reply_centent" v-if="current == 1">
			<div class="start-col">
				<div class="setting_sub_title">接入欢迎语</div>
			    <div class="setting_header_desc">客服手动或自动接入时，自动回复以下内容。</div>
			</div>
			<div>
				<el-input
					type="textarea"
					placeholder="请输入欢迎语"
					rows="4"
					maxlength="300"
					show-word-limit
					v-model="welcomeText">
				</el-input>
			</div>
			<div style="margin-top: 30px;text-align: end;margin-right:30px" >
				<el-button style="width: 90px;font-size: 16px" @click="welcomeText = ''"  type="info">取消</el-button>

				<el-button style="width: 90px;font-size: 16px"  @click="saveWelcomeText" type="success" size="medium ">保存</el-button>
			</div>
		</div>
		<div class="reply_centent" v-if="current == 2">		
			<div class="start-col" style="margin-top: 30px;">
				<div class="setting_sub_title">关键词回复</div>
			   <div style="display: flex;align-items: baseline;">
				<div class="setting_header_desc">用户通过关键词客服快速回复</div>
			   </div>
			</div>

			<div>
				<div style="display: flex;margin-bottom:15px" v-for="(item, index) in keywordList" :key="index">
					<div style="width: 30%;margin-right:20px">
						<el-input
							type="textarea"
							placeholder="点击新增关键词"
							rows="4"
							maxlength="20"
							show-word-limit
							v-model="item.quest">
						</el-input>
					</div>
					<div style="width: 70%;">
						<el-input
							type="textarea"
							placeholder="点击新增回复"
							rows="4"
							maxlength="500"
							show-word-limit
							v-model="item.replay">
						</el-input>
					</div>
					<div @click="delKeywords(index)" v-if="index != 0" style="width: 50px;margin-top: 30px;">
						<i class="el-icon-delete"></i>
					</div>
					<div v-else style="width: 50px;margin-top: 30px;">
					</div>
				</div>
				<div style="margin-top: 30px;" @click="addKeywords">
					<el-button type="primary" icon="el-icon-circle-plus-outline">新增关键词</el-button>
				</div>
				<div style="margin-top: 30px;text-align: end;margin-right:30px" >
					<el-button style="width: 90px;font-size: 16px" @click="keywordsCancel"  type="info">取消</el-button>

					<el-button style="width: 90px;font-size: 16px"  @click="saveKeywords" type="success" size="medium ">保存</el-button>
				</div>
			</div>
		</div>
		
	</el-container>
</template>


<script>
	import GroupItem from '../components/group/GroupItem';
	import FileUpload from '../components/common/FileUpload';
	import GroupMember from '../components/group/GroupMember.vue';
	import AddGroupMember from '../components/group/AddGroupMember.vue';
	import HeadImage from '../components/common/HeadImage.vue';
	export default {
		name: "group",
		components: {
			GroupItem,
			GroupMember,
			FileUpload,
			AddGroupMember,
			HeadImage
		},
		data() {
			return {
				tabList: [
					"常见问题",
					"接入欢迎语",
					"关键词回复"
				],
				current: 0,
				startText: "",
				welcomeText: "",
				custQuest: [
					{
						quest: '',
						replay: '',
					}
				],
				keywordList: [
					{
						quest: '',
						replay: '',
					}
				],
				
			};
		},
		mounted() {
			this.getKefuSetting()
		},
		methods: {
			selectIndex(index){
				this.current = index
			},
			getKefuSetting(){
			
				this.$http({
					url: "/kefu/getKefuSpeech",
					method: 'get'
				})
				.then((data) => {
					console.log(112,data)
					this.startText =  data.startText? data.startText: ''
					this.welcomeText =  data.welcomeText? data.welcomeText: ''
					this.custQuest =  data.custQuestStr? JSON.parse(data.custQuestStr,true): [{
						quest: '',
						replay: '',
					}]
					this.keywordList =  data.keywordStr? JSON.parse(data.keywordStr,true): [{
						quest: '',
						replay: '',
					}]
				}).catch(err => {
					console.log('err',err)
					this.$message.error(err.message);
				})
			},
			saveQuset(){
				if(!this.startText){
					this.$message.error("开场白不能为空！");
					return;
				}
				this.custQuest.forEach( el => {
				
					if(!el.quest || !el.replay){
						this.$message.error("请完善常见问题表单！");
					    return;
					}
				})

				this.$http({
					url: "/kefu/custQuestSave",
					method: 'post',
					data: {
						startText: this.startText,
						custQuestStr: JSON.stringify(this.custQuest)
					}
				})
				.then((data) => {
					this.$message.success("保存成功!");
				}).catch(err => {
					console.log('err',err)
					this.$message.error(err.message);
				})
			},

			
			saveWelcomeText(){
				if(!this.welcomeText){
					this.$message.error("欢迎语不能为空！");
					return;
				}
			
				this.$http({
					url: "/kefu/welcomeTextSave",
					method: 'post',
					data: {
						welcomeText: this.welcomeText,
					}
				})
				.then((data) => {
					this.$message.success("保存成功!");
				}).catch(err => {
					console.log('err',err)
					this.$message.error(err.message);
				})
			},

			saveKeywords(){
			
				this.keywordList.forEach( el => {
				
					if(!el.quest || !el.replay){
						this.$message.error("请完善关键词回复表单！");
						return;
					}
				})

				this.$http({
					url: "/kefu/keywordsSave",
					method: 'post',
					data: {
						keywordStr: JSON.stringify(this.keywordList)
					}
				})
				.then((data) => {
					this.$message.success("保存成功!");
				}).catch(err => {
					console.log('err',err)
					this.$message.error(err.message);
				})
			},

			

			addQuest(){
				if(this.custQuest.length > 10){
					this.$message.error("问题数量已达到上限！");
					return;
				}
				this.custQuest.push(
					{
						quest: '',
						replay: '',
					}
				)
			},
			delQuest(index){
				this.custQuest.splice(index, 1);  
				this.custQuest.sort((a, b) => a - b);  

			},
			questCancel(){
				this.custQuest = [
					{
						quest: '',
						replay: '',
					}
				]

				console.log(111,this.custQuest)
			},
			addKeywords(){
				if(this.keywordList.length > 20){
					this.$message.error("关键词回复已达到上限！");
					return;
				}
				this.keywordList.push(
					{
						quest: '',
						replay: '',
					}
				)
			},
			delKeywords(index){
				this.keywordList.splice(index, 1);  
				this.keywordList.sort((a, b) => a - b);  

			},
			keywordsCancel(){
				this.keywordList = [
					{
						quest: '',
						replay: '',
					}
				]

				console.log(111,this.custQuest)
			},
			
		},
		computed: {
 
		}
	}
</script>
 
<style lang="scss">
.setting-page{
	padding: 20px 60px 20px 60px;
	display: flex;
	flex-direction: column;
}

.reply_panel {
    display: flex;
    margin-top: 32px;
    padding-bottom: 16px;
    padding-top: 16px;
	background-color: #fff;
    border-radius: 12px;
	height: 30px;
	width: 100%;
}
.reply_tab {
	color: #b1b2b3;
    cursor: pointer;
    font-size: 14px;
    line-height: 30px;
	margin-left: 30px;
}
.active{
	color:#1a1b1c;
    font-weight: 500;
	font-size: 15px;
}
.reply_centent{
	margin-top: 30px;
	background-color: #fff;
    border-radius: 12px;
	padding: 16px;
}
.setting_sub_title {
    color: #1a1b1c;
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 24px;
}
.setting_header_desc {
    color: #b1b2b3;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 24px;
    margin-top: 4px;
}
.start-col{
	display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.el-textarea__inner{
	border-radius: 5px !important;
	border: #9badec 1px solid !important;
}
</style>